import moment from "moment"
import React, { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"

import Settings from "../../common/Settings"
import "./visit.css"

import AssignCustomer from "../Customers/AssignCustomer"

import AssignUser from "../Users/AssignUser"
import API from "../../common/API"
import AddEditVisit from "./AddEditVisit"
import { parsedUser } from "../../common/GetCurrentUser"
import { useHistory } from "react-router-dom"

import VisitGallery from "./VisitGallery"
import VisitMap from "./VisitMap"
import AddEditPlace from "./AddEditPlace"
import { t } from "i18next"
import { useTranslation } from "react-i18next"
import AssignPlaceStatus from "./AssignPlaceStatus"
import VisitList from "./VisitList"
import { Routes } from "../../routes"

const VisitDetails = props => {
  const [model, setModel] = useState(props.model)
  const user = parsedUser()
  const history = useHistory()
  const { t } = useTranslation("global")
  const [showCategory, setShowCategory] = useState(false)
  const [showAssignCustomer, setShowAssignCustomer] = useState(false)

  const toggleAssignCustomer = () => {
    setShowAssignCustomer(!showAssignCustomer)
    if (showAssignCustomer) {
      requestDetails()
    }
  }
  const [visits, setVisits] = useState([])

  const [position, setPosition] = useState({
    lat: 18.4718609,
    lng: -69.8923187,
  })
  const requestVisits = async () => {
    let query = "placeId=" + model.id
    // query += "&OrganizationId=" + user.OrganizationId;
    let requestAPI = await API.getAction("place/VisitList", query)
    if (requestAPI.data.status === "ok") {
      setVisits(requestAPI.data.response)
    }
  }

  const [totals, setTotals] = useState({
    totalApartments: 0,
    totalRoomsPerApartment: 0,
    totalBathrooms: 0,
    totalHabitableArea: 0,
    totalNonHabitableArea: 0,
  })

  const requestDetails = async () => {
    let query = "id=" + model.id
    // query += "&OrganizationId=" + user.OrganizationId;
    let requestAPI = await API.getAction("place/Details", query)
    if (requestAPI.data.status === "ok") {
      let details = requestAPI.data.response
      setModel(requestAPI.data.response)

      let totalApartments = 0,
        totalRoomsPerApartment = 0,
        totalBathrooms = 0,
        totalHabitableArea = 0,
        totalNonHabitableArea = 0

      totalApartments =
        details.quantityOfFloor * 1 * details.departmentPerFloor * 1
      totalRoomsPerApartment = totalApartments * details.roomsPerApartment * 1

      totalBathrooms = totalRoomsPerApartment * 1.25

      totalHabitableArea =
        details.quantityOfFloor *
        1 *
        details.departmentPerFloor *
        1 *
        details.estimatedDimensions *
        1

      totalNonHabitableArea = totalHabitableArea * 0.2

      setTotals({
        ...totals,
        totalApartments: totalApartments,
        totalRoomsPerApartment: totalRoomsPerApartment,
        totalBathrooms: totalBathrooms,
        totalHabitableArea: totalHabitableArea,
        totalNonHabitableArea: totalNonHabitableArea,
      })
    }
  }

  const [showAddEditVisit, setShowAddEditVisit] = useState(false)
  const toggleAddEditVisit = () => {
    setShowAddEditVisit(!showAddEditVisit)
  }

  useEffect(() => {
    if (model) {
      setPosition({ lat: model.latitude * 1, lng: model.longitude * 1 })

      requestVisits()
    }
  }, [model])

  useEffect(() => {
    requestDetails()
  }, [])

  const toggleShowCategory = selected => {
    setShowCategory(!showCategory)
  }

  const [showAddEdit, setShowAddEdit] = useState(false)
  const toggleAddEdit = () => {
    setShowAddEdit(!showAddEdit)
    if (showAddEdit) {
      requestDetails()
    }
  }

  const [showAssignUsers, setShowAssignUsers] = useState(false)
  const toggleAssignUsers = () => {
    setShowAssignUsers(!showAssignUsers)
    if (showAssignUsers) {
      requestDetails()
    }
  }
  const [isLoading, setIsLoading] = useState(false)
  const savePlace = async () => {
    try {
      setIsLoading(true)
      let request = await API.postAction("place/AddEdit", model)

      console.log(request)
      // if (request.data.status === "ok") {
      //   requestDetails()
      // }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }
  const createChat = async () => {
    try {
      let query = {
        placeId: model.id,
        createdById: user.ID,
      }
      var request = await API.postAction("chat/createChat", query)
      console.log(request)
      history.push(`/chat/${request.data.response.chatId}`)
    } catch (ex) {
      console.error(ex)
    }
  }
  const [showAssignStatus, setShowAssignStatus] = useState(false)
  const toggleAssignStatus = () => {
    setShowAssignStatus(!showAssignStatus)
    if (showAssignStatus) {
      requestDetails()
    }
  }
  const [showMap, setShowMap] = useState(true)
  const toggleMap = () => {
    setShowMap(!showMap)
  }

  const [showPhoto, setShowPhoto] = useState(false)
  const togglePhoto = () => {
    setShowPhoto(!showPhoto)
  }

  if (!model) {
    return <></>
  }

  return (
    <>
      {showAddEditVisit && (
        <AddEditVisit
          show={showAddEditVisit}
          toggle={toggleAddEditVisit}
          place={model}
        />
      )}
      {showAddEdit && (
        <AddEditPlace show={showAddEdit} toggle={toggleAddEdit} model={model} />
      )}
      {/* {showCategory ? <PhotoCategoryAssign show={showCategory} toggle={toggleShowCategory} PhotoId={selectedPhoto} /> : null} */}
      {showAssignCustomer ? (
        <AssignCustomer
          show={showAssignCustomer}
          toggle={toggleAssignCustomer}
          place={model}
        />
      ) : null}
      {showAssignUsers ? (
        <AssignUser
          show={showAssignUsers}
          toggle={toggleAssignUsers}
          place={model}
        />
      ) : null}
      {showAssignStatus ? (
        <AssignPlaceStatus
          show={true}
          toggle={toggleAssignStatus}
          place={model}
        />
      ) : null}
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="xl"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Row className="mx-0 w-100">
            <Col>
              {" "}
              <h3 className="d-inline">{model.name}</h3>
              <button
                className="ms-2 btn btn-sm btn-primary"
                onClick={() => toggleAddEdit()}
                type="button"
                title="Editar"
              >
                <i className="fa fa-edit me-2"></i>
                {t("Edit")}
              </button>
            </Col>
            <Col>
              <button
                className={"ms-2 btn btn-sm " + (showMap ? "btn-primary" : "")}
                onClick={() => toggleMap()}
                type="button"
                title="Mapa"
              >
                <i className="fa fa-map me-2"></i>
                {t("Map")}
              </button>

              <button
                className={
                  "ms-2 btn btn-sm " + (showPhoto ? "btn-primary" : "")
                }
                onClick={() => togglePhoto()}
                type="button"
                title="Galeria"
              >
                <i className="fa fa-photo-film me-2"></i>
                {t("Photos")}
              </button>

              <button
                className="ms-2 btn  btn-sm "
                onClick={() => createChat()}
              >
                <i className="fa fa-comments me-2"></i> {t("Chat")}
              </button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ overflow: "auto" }}>
          <Container>
            <Row class>
              <Col xs={12} md={12} xl={4} className="p-0">
                <Card className="shadow-sm m-2 ">
                  <img
                    src={`${Settings.BasePathForAPI}file/getThumbnail?filename=${model.image} `}
                    loading="lazy"
                    alt=""
                    className=" card-img-top "
                    style={{ maxHeight: "250px" }}
                  />

                  <Card.Body className="p-0">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>{t("Assigned to")}</td>
                          <td>
                            {model.assignedTo ? (
                              <button
                                className="clickable btn btn-outline-primary btn-sm"
                                onClick={() => toggleAssignUsers()}
                              >
                                {model.assignedToObj.firstName}{" "}
                                {model.assignedToObj.lastName}
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-sm ms-2"
                                onClick={() => toggleAssignUsers()}
                              >
                                {t("Assign")}
                              </button>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2}>
                            {t("Customer")}
                            <br />
                            {model.customer ? (
                              <>
                                <span
                                  onClick={() => {
                                    history.push(
                                      `${Routes.CustomerDetails.clean}${model.customer.id}`
                                    )
                                  }}
                                  className="clickable"
                                >
                                  <b> {model.customer.name}</b>
                                </span>
                                <button
                                  className="clickable btn btn-outline-primary btn-sm ms-2"
                                  onClick={() => toggleAssignCustomer()}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>
                              </>
                            ) : (
                              <button
                                className="btn btn-outline-primary  btn-sm "
                                onClick={() => toggleAssignCustomer()}
                              >
                                {t("Link")}
                              </button>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>{t("Status")}</td>
                          <td>
                            <button
                              className="btn btn-outline-primary btn-sm "
                              type="button"
                              onClick={() => toggleAssignStatus()}
                            >
                              <i
                                className={
                                  (model.placeStatus && model.placeStatus.icon
                                    ? model.placeStatus.icon
                                    : " fa fa-circle ") + " me-2"
                                }
                                style={{
                                  color:
                                    model.placeStatus &&
                                    model.placeStatus.color,
                                }}
                              ></i>
                              {model.placeStatus
                                ? model.placeStatus.name
                                : "N/A"}
                            </button>
                          </td>
                        </tr>

                        {/* <tr>
													<td>Proyecto</td>
													<td>
														{model.name}
													</td>
												</tr> */}
                        {model.category ? (
                          <tr>
                            <td>{t("Category")}</td>
                            <td>{model.category.name}</td>
                          </tr>
                        ) : null}
                        {model.stage ? (
                          <tr>
                            <td>{t("Stage")}</td>
                            <td>{model.stage.name}</td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>{t("Date of creation")}</td>
                          <td>{moment(model.created).fromNow()}</td>
                        </tr>
                        <tr>
                          <td>{t("Created by")}</td>
                          <td>
                            {model.createdBy
                              ? model.createdBy.firstName +
                                " " +
                                model.createdBy.lastName
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Body>
                  <Card.Footer>
                    <button
                      className="btn btn-block btn-secondary "
                      onClick={() => toggleAddEditVisit()}
                    >
                      <i className="fa fa-plus me-2"></i>
                      <span className="d-none d-md-inline">
                        {t("Add visit")}
                      </span>
                    </button>
                  </Card.Footer>
                </Card>
                {/* TECHNICAL DETAILS */}
                <Card className="shadow-sm m-2">
                  <Card.Header className="p-1 px-2">
                    <Card.Title> {t("Technical details")}</Card.Title>
                  </Card.Header>
                  <Card.Body className=" bg-light">
                    <Row>
                      <Col xs={12}>
                        <label>{t("Quantity of Floors")}</label>
                        <br />
                        <input
                          type="number"
                          className="form-control"
                          value={model.quantityOfFloor}
                          onChange={e => {
                            setModel({
                              ...model,
                              quantityOfFloor: e.target.value,
                            })
                          }}
                        ></input>
                      </Col>

                      <Col xs={12} className="mt-2">
                        <label>{t("Apto. por piso")}</label>
                        <br />
                        <input
                          type="number"
                          className="form-control"
                          value={model.departmentPerFloor}
                          onChange={e => {
                            setModel({
                              ...model,
                              departmentPerFloor: e.target.value,
                            })
                          }}
                        ></input>
                      </Col>
                      <Col xs={12} className="mt-2">
                        <label>{t("Habitaciones por apt.")}</label>
                        <br />
                        <input
                          type="number"
                          className="form-control"
                          value={model.roomsPerApartment}
                          onChange={e => {
                            setModel({
                              ...model,
                              roomsPerApartment: e.target.value,
                            })
                          }}
                        ></input>
                      </Col>
                      <Col xs={12} className="mt-2">
                        <label>{t("Estimated Dimensions")}</label>
                        <br />
                        <input
                          type="number"
                          className="form-control"
                          value={model.estimatedDimensions}
                          onChange={e => {
                            setModel({
                              ...model,
                              estimatedDimensions: e.target.value,
                            })
                          }}
                        ></input>
                      </Col>
                      <Col xs={12} md={6} className="mt-2">
                        <label>{t("Credit Code")}</label>
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          value={model.creditCode}
                          onChange={e => {
                            setModel({
                              ...model,
                              creditCode: e.target.value,
                            })
                          }}
                        ></input>
                      </Col>
                      <Col xs={12} md={6} className="mt-2">
                        <label>{t("Work Code")}</label>
                        <br />
                        <input
                          type="text"
                          className="form-control"
                          value={model.workCode}
                          onChange={e => {
                            setModel({
                              ...model,
                              workCode: e.target.value,
                            })
                          }}
                        ></input>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <button
                      disabled={isLoading}
                      className="btn btn-block btn-primary"
                      onClick={() => savePlace()}
                    >
                      <i className="fa fa-save me-2"></i>
                      {t("Save")}
                    </button>
                  </Card.Footer>
                </Card>

                {/* VOL table */}
                {totals && totals.totalApartments > 0 && (
                  <Card className="shadow-sm m-2">
                    <Card.Header className="p-1 px-2">
                      <Card.Title> {t("Tabla Volumen")}</Card.Title>
                    </Card.Header>
                    <Card.Body className=" bg-light">
                      <Row>
                        <Col xs={12}>
                          <label>{t("Unidades de apto.")}</label>
                          <br />
                          <input
                            type="number"
                            disabled
                            className="form-control"
                            value={totals.totalApartments}
                          ></input>
                        </Col>
                        <Col xs={12}>
                          <label>{t("Habitaciones por apto.")}</label>
                          <br />
                          <input
                            type="number"
                            disabled
                            className="form-control"
                            value={totals.totalRoomsPerApartment}
                          ></input>
                        </Col>
                        <Col xs={12}>
                          <label>{t("No. de Baños")}</label>
                          <br />
                          <input
                            type="number"
                            disabled
                            className="form-control"
                            value={totals.totalBathrooms}
                          ></input>
                        </Col>

                        <Col xs={12}>
                          <label>{t("Área vivible mt²)")}</label>
                          <br />
                          <input
                            type="number"
                            disabled
                            className="form-control"
                            value={totals.totalHabitableArea}
                            onChange={e => {
                              setModel({
                                ...model,
                                quantityOfFloor: e.target.value,
                              })
                            }}
                          ></input>
                        </Col>

                        <Col xs={12}>
                          <label>{t("Área comun  mt²")}</label>
                          <br />
                          <input
                            type="number"
                            disabled
                            className="form-control"
                            value={totals.totalNonHabitableArea}
                            onChange={e => {
                              setModel({
                                ...model,
                                quantityOfFloor: e.target.value,
                              })
                            }}
                          ></input>
                        </Col>
                        <Col xs={12}>
                          <label>{t("Total  mt²")}</label>
                          <br />
                          <input
                            type="number"
                            disabled
                            className="form-control"
                            value={
                              totals.totalNonHabitableArea +
                              totals.totalHabitableArea
                            }
                            onChange={e => {
                              setModel({
                                ...model,
                                quantityOfFloor: e.target.value,
                              })
                            }}
                          ></input>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )}
              </Col>

              <Col xs={12} md={12} lg={8}>
                <Container className="pt-4">
                  {/* 						
								<h4>Contactos
									<button className="btn btn-sm btn-primary ms-2" type="button">
										<i className="fa fa-plus"></i>
									</button>

								</h4>
								<hr /> */}
                  {/* VISITS */}
                  <h4>{t("Visits")}</h4>
                  <hr />
                  <VisitList visits={visits} />

                  {/* MAP */}
                  {console.log(model)}
                  {showMap && <VisitMap model={model} />}
                  {showPhoto && (
                    <Card className="mt-4 shadow-sm">
                      <Card.Header className="px-2 p-1">
                        <h5>{t("Photos")}</h5>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <VisitGallery id={model.id} />
                      </Card.Body>
                    </Card>
                  )}
                </Container>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="p-1"></Modal.Footer>
      </Modal>
    </>
  )
}

export default VisitDetails
